require('./bootstrap');
require('modaal');

function isMobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

$('.quote-modal-btn').modaal({
  content_source: '#quote-modal',
  width: 400,
});

$(document).ready(function () {
  let topMenuHeight = $('header').height();
  let whatsappBtn = $('.float-btn');

  const safeOffset = 47;
  $(document).on('click', 'a[href^="#"]', function (e) {
    e.preventDefault();
    let Link = $(this).attr('href');
    $('html, body').scrollTop($(Link).offset().top - (topMenuHeight + safeOffset));
  });

  let lastId, topMenu = $('nav'),
    menuItems = topMenu.find('a'),
    scrollItems = menuItems.map(function () {
      let item = $($(this).attr('href'));
      if (item.length) {
        return item;
      }
    });

  menuItems.click(function (e) {
    let href = $(this).attr('href'),
      offsetTop = href === "#" ? 0 : $(href).offset().top - (topMenuHeight + safeOffset);
    $('html, body').stop().scrollTop(offsetTop);
    e.preventDefault();
  });

  $(window).scroll(function () {
    let fromTop = $(this).scrollTop() + (topMenuHeight+ safeOffset+2);
    let cur = scrollItems.map(function () {
      if ($(this).offset().top < fromTop)
        return this;
    });
    cur = cur[cur.length - 1];
    let id = cur && cur.length ? cur[0].id : "";

    if (lastId !== id) {
      lastId = $();
      menuItems
        .removeClass('active')
        .filter('[href="#' + id + '"]')
        .addClass('active');
    }

    if ($(window).scrollTop() + $(window).height() > $(document).height() - 1) {
      $('nav a').removeClass('active');
      $('nav a:last').addClass('active');
    }

    $(window).scroll(function () {
      if ($(window).scrollTop() > topMenuHeight + 250) {
        $('header').addClass('fixed');
      } else {
        $('header').removeClass('fixed');
      }
    });

    if(isMobile()){
      $(window).scroll(function (){
        if ($(window).scrollTop() > topMenuHeight + 100) {
          whatsappBtn.addClass('fixed');
        } else {
          whatsappBtn.removeClass('fixed');
        }
      })
    }
  });
});
